.ConfirmationPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
}

.ConfirmationPage h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.ConfirmationPage p {
  font-size: 1em;
  color: #555;
  line-height: 1.5;
}

.ConfirmationPage h2 {
  font-size: 1.5em;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #333;
}

.ConfirmationPage ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.ConfirmationPage li {
  background-color: #f7f7f7;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  color: #333;
  transition: background-color 0.3s ease;
}

.ConfirmationPage li:hover {
  background-color: #e9ecef;
}

.ConfirmationPage .supportingText {
  padding-top: 10px;
  font-size: 0.9em;
  color: #666;
  line-height: 1.4;
  border-top: 1px solid #eee;
  margin-top: 10px;
}

/* Responsive design adjustments */
@media (max-width: 600px) {
  .ConfirmationPage {
    margin: 20px;
    padding: 15px;
  }

  .ConfirmationPage h1 {
    font-size: 1.5em;
  }

  .ConfirmationPage h2 {
    font-size: 1.2em;
  }

  .ConfirmationPage li {
    padding: 15px 20px; /* Larger padding for better touch targets on mobile */
  }

  .ConfirmationPage p {
    font-size: 0.9em; /* Slightly smaller text to accommodate smaller screens */
  }
}


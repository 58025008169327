.error-message {
    background-color: #ffdddd;
    color: #d8000c;
    border: 1px solid #d8000c;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .error-message h2 {
    color: #d8000c;
  }
  
  .error-message p {
    color: #333;
  }
  
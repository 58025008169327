/* index.css - Global styles and font imports */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background: #f4f7f6; /* Global background */
  color: #333; /* Global text color */
}

/* Global box-sizing */
*, *::before, *::after {
  box-sizing: border-box;
}

.Question {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .Question label {
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
    color: #333;
  }
  
  .Question input[type="radio"] {
    margin-right: 10px;
    cursor: pointer;
  }
  
  /* Improve the look of the radio button label when selected */
  .Question input[type="radio"]:checked + label {
    color: #007bff;
  }

  .QuestionForm .Question.error {
    border: 2px solid red; /* Example style to highlight error */
  }  
  
  @media (max-width: 600px) {
    .Question input[type="radio"] {
      height: 24px;
      width: 24px;
      margin-right: 15px; /* Increase spacing to make it easier to tap */
    }
  
    .Question label {
      font-size: 1rem; /* Adjust font size for smaller screens */
    }
}
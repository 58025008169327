.QuestionForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
}

.QuestionForm h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.QuestionForm form {
  width: 100%;
}

.QuestionForm button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Makes the button take the full width of the form */
}

.QuestionForm button:hover {
  background-color: #0056b3;
}

.QuestionForm p.error {
  color: red;
}

@media (max-width: 600px) {
  .QuestionForm button {
    padding: 15px 20px; /* Increase padding for the submit button */
  }
}

/* Progress Bar Styles */
.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 20px;
  margin-bottom: 20px;
  height: 20px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.progress {
  background-color: #4caf50;
  height: 100%;
  border-radius: 20px;
  transition: width 0.4s ease;
}

/* Welcome.css - Styles specific to the Welcome component */
.WelcomeForm {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  margin: 50px auto;
}

.WelcomeForm label {
  display: block;
  margin-bottom: 5px;
}

.WelcomeForm input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.WelcomeForm input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.WelcomeForm button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.WelcomeForm button:hover {
  background-color: #0056b3;
}

.WelcomeForm h1 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 300;
  text-align: center;
}

.WelcomeForm p.error {
  color: red
}
